import { useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";

import Autocomplete from "components/forms/autocomplete";
import { Fragment , useEffect, useState} from "react";
import Api from "api/requests";
import { createFiltersUrl, getFullMediaUrl, decodeArrayToObject } from "app/static_func";
import * as routes from "constants/routes";

import "./index.scss";
import SearchIcon from "assets/icons/search.svg";
import RoundSmartAgent from "assets/icons/round-smart-agent.svg";
import Stairs from "../sliderstairs";
import { useDispatch } from "react-redux";
import Actions from "redux/actions";
import googleAnalyticsManager from "analytics/googleAnalytics";
import CountUp from "react-countup";
import ReactHtmlParser from "react-html-parser";
import Lottie from "../../../../components/animation/lottie";
import LottieAnimation from "../../../../components/carAnimation/car-animation";
import carAnimationMobileJson from "../../../../components/carAnimation/carUpAnimationMobile.json";
import carAnimationDesktopJson from "../../../../components/carAnimation/carUpAnimationDekstop.json";


let debounceTimer = null;

function Search(props) {
  const deviceState = useSelector((store) => store.deviceState);
  const options = useSelector((store) => store.AutoCompleteResults);
  const priceStairs = useSelector((store) => store.gd["price-stairs"]);
  const carCategories = useSelector((store) => store.gd["car-categories"]);
  const text = useSelector((store) => store.gd.translationsArr);
  const bodyItem = useSelector((store) => store.gd['body-item']);
  const [query, setQuery] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const clickHereToWatch = useSelector((store) => store.gd.translationsArr['click-here-to-watch']);
  const carInSite = useSelector((store) => store.gd.translationsArr['car-in-site']);
  const numOfActiveCars = useSelector((store) => store.gd['active_cars']);


  const [updatedCars,setUpdatedCars] = useState(true);

  const { imageMobile, imageDesktop } = props;

  function getBanner(image) {
    // const tradeInUrl = bodyItem.url
    const tradeInUrl = routes.tradeIn;
    return (
      <NavLink className="banner-wrapper" to={tradeInUrl}>
        <img src={image} alt="\" />
      </NavLink>
    );
  }
  function getOptions() {
    let results = [];
    if (options) {
      options.forEach((item, index) => {
        let option = {};
        option.filters = { ...item.filters };
        option.value = item.model;
        option.id = index;
        option.topModel = [item.data["top_model"]];
        results.push(option);
      });
    }
    return results;
  }

  function getCarsByQuery(query) {
    Api.getCarsByQuery({ payload: { query: query } }).then((res) => {
      if (!res) {
        const query = document.getElementById('auto-input-id');
        query.blur();
      }
    }).catch((e) => {

    });
  }

  function handleQueryInput(query) {
    setQuery(query.target.value);
    setUpdatedCars(false);
    if (query.target.value === "") {
      dispatch(Actions.setAutoCompleteResults(null))
    }

    if (query.target.value.length >= 2) {
      let q = query.target.value;
      debounce(() => { getCarsByQuery(q) }, 1500)
    }

    // if (this.props.deviceState !== "desktop_large") {
    //   window.scrollTo(0, this.searchRef.current.offsetTop - 90);
    // } else {
    //   window.scrollTo(0, this.searchRef.current.offsetTop - 440);
    // }
  }

  function debounce(callback, time) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(callback, time);
  }

  function handleClickOutside() {
    // todo: to fix it
  }

  function handleAutocompleteOptionSelect(res) {
    //route to results page with URL params
    res.filters["page"] = 0;
    res.filters["limit"] = 20;
    // res.filters["total_filters"] = 1;
    // res.filters['query'] = res.value;

    let newPayload = {
      ...res.filters,
      ...decodeArrayToObject(res.topModel, 'top_model_id')
    };

    history.push(routes.results + createFiltersUrl(newPayload));
  }

  function handleClick() {
    const payload = {};

    payload["page"] = 0;
    payload["limit"] = 20;
    payload['query'] = query;
    history.push(routes.results + createFiltersUrl(payload));
    if (payload.query) {
      googleAnalyticsManager.Search({query: payload.query})
  }
  }

  function handleAllQueryClick(e) {
    e.stopPropagation();
    e.preventDefault();
    handleClick()
}



  function getAutoComplete() {
    return (
      <div className="autoComplete-warrper">
        <Autocomplete
          className="autoComplete"
          options={getOptions()}
          placeholder={
            <span className="placeholder">
              <strong>{text["search"]} </strong>
              {text["model_or_key"]}
            </span>
          }
          query={query}
          onChange={handleQueryInput}
          onClickOutside={handleClickOutside}
          customPlaceholder={true}
          onSelect={handleAutocompleteOptionSelect}
          link={routes.results}
          onKeyDown={handleClick}
          extraOptionClicked={handleAllQueryClick}
          extraOptionText={`${text['search-button-all-results']} ${query}`}
        />
        <button className="search-button" onClick={handleClick}>
          <img className="search-icon" src={SearchIcon} alt="\" />
        </button>
      </div>
    );
  }

  function getSmartAgent() {
    return (
      <div className="smart-agent-wrapper">
        <NavLink to={routes.smartagent}>
          <button className="smart-agent-btn">
            <img className="smart-agent-icon" src={RoundSmartAgent} alt="\" />
          </button>
        </NavLink>
        <span className="smart-agent-text">{text["smart_agent"]}</span>
      </div>
    );
  }
  function getAdvencedSearch() {
    return (
      <NavLink to={routes.results}>
        <button className="advanced-search-btn">
          <span className="advanced-search-text">
            {text["advanced_search"]}
          </span>
        </button>
      </NavLink>
    );
  }

  function getAllactiveCars() {
    return (
        <NavLink className="all-buttons-wrapper" to={routes.results}>
            <div className="num-of-cars-and-site-cars"><h4  id="counter" className="animation-counter">
              <CountUp
              start={0}
              end={parseInt(numOfActiveCars)}
              duration={3}
              delay={1}
            />
            </h4><h4>{carInSite}</h4></div>
            <h3>{clickHereToWatch}</h3>
        </NavLink>
    );
  }
  return (
    <Fragment>
      <section className="search-container">
        {deviceState.notDesktop && (
          <Fragment>
            {getBanner(imageMobile)}
            <LottieAnimation animation={carAnimationMobileJson} className="animation-car"/>
            {getAllactiveCars()}
            {getAutoComplete()}
            <div className="advanced-warrper">
              {getSmartAgent()}
              {getAdvencedSearch()}
            </div>
          </Fragment>
        )}
        {!deviceState.notDesktop && (
            <Fragment>
              <div className="smart-agent-container">{getSmartAgent()}</div>
              <div className="advanced-search">
                <div className="wrapper-of-search">
                  <LottieAnimation animation={carAnimationDesktopJson} className="animation-car"/>
                  {getAllactiveCars()}
                  <div className="all-active-cars">
                    {getAutoComplete()}
                    {getAdvencedSearch()}
                  </div>
                </div>

              {getBanner(imageDesktop)}
            </div>
          </Fragment>
        )}
      </section>
      <section className="stairs-container">
        <div className="stairs budget-wrapper">
          <div className="title ">
            <span className="position">{text["search_badget"]}</span>
          </div>

          <Stairs
            options={priceStairs}
            history={history}
            stairs_id={"price_stairs_id"}
          />
        </div>
        <div className="stairs usage-wrapper">
          <div className="title ">
            <span className="position">{text["search_use_case"]}</span>
          </div>
          <Stairs
            options={carCategories}
            history={history}
            stairs_id={"category_id"}
            showText={true}
          />
        </div>
      </section>
    </Fragment>
  );
}
export default Search;
